import React, { useContext } from "react";
import { PricingCardsContext } from "../../../Contexts/PircingCardsContext";
import ListItem from "./utils/CheckListItem";
import { TailWrapper, TailTitle, TailContent } from "./utils/styles";
import CardImg from "./utils/CardImg";
import { useStaticQuery, graphql } from "gatsby";
import PriceButton from "./utils/PriceButton";

const PremiumInside = () => {
  const imageData = useStaticQuery(graphql`
    query {
      sellPrep: file(relativePath: { eq: "pricingCards/premium_inside.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const {
    servicePrices: { premiumInside: price },
  } = useContext(PricingCardsContext);

  return (
    <TailWrapper>
      <TailTitle>Kompeksowe czyszczenie wnętrza</TailTitle>
      <CardImg
        fluid={imageData.sellPrep.childImageSharp.fluid}
        alt="Przygotowanie do sprzedaży"
      />
      <TailContent>
        <ListItem>Pranie elementów tapicerowanych</ListItem>
        <ListItem>odkurzanie</ListItem>
        <ListItem>mycie plastików</ListItem>
        <ListItem>mycie szyb</ListItem>
      </TailContent>
      <PriceButton from price={price} />
    </TailWrapper>
  );
};

export default PremiumInside;
