import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledLogo = styled(Img)`
  width: 150px;
  height: auto;
  transform: ${({ menuShown }) =>
    menuShown ? "translateY(0)" : "translateY(10px)"};
  transition: transform 400ms linear;
  margin-top: 10px;
  z-index: 1;
  cursor: pointer;
  @keyframes showDown {
    0% {
      transform: translateY(-100%) scale(1, 0);
      opacity: 0;
    }
    100% {
      transform-origin: top;
      transform: translateY(0) scale(1, 1);
      opacity: 1;
    }
  }
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    width: 185px;
    opacity: 0;
    animation: showDown 1s ease 1s forwards;
    margin-top: 20px;
  }
`;

const Logo = ({ menuShown }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <a href={"#oferta"}>
      <StyledLogo
        fluid={data.logo.childImageSharp.fluid}
        menuShown={menuShown}
        alt="GarCar Logo"
        loading="eager"
      />
    </a>
  );
};

export default Logo;
