import React from "react";
import styled from "styled-components";

const StyledMenuItem = styled.li`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.whitest};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightDark};
  &:last-child a::after {
    content: "-10%";
    position: absolute;
    top: 5px;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.yellow};
    transform: translateX(-10px) rotate(5deg);
  }
  a {
    color: inherit;
    text-decoration: none;
    display: block;
    position: relative;
    cursor: pointer;
  }
  @keyframes showDown {
    0% {
      transform: translateY(-100%) scale(1, 0);
      opacity: 0;
    }
    100% {
      transform-origin: top;
      transform: translateY(0) scale(1, 1);
      opacity: 1;
    }
  }
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    width: auto;
    border-bottom: none;
    background-color: transparent;
    margin: 15px;
    opacity: 0;
    animation: ${({ delay }) =>
      `1s showDown ${delay * 0.5 + 1.5}s ease forwards`};
    &::after {
      content: "";
      width: 100%;
      display: block;
      height: 3px;
      background-color: ${({ theme }) => theme.colors.whitest};
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.5s ease;
    }
    &:hover {
      transform: scale(1.05);
      &::after {
        transform: scaleX(1);
      }
    }
  }
`;
const ManuItem = React.memo(({ children, to, index, topRightText }) => {
  return (
    <StyledMenuItem delay={index} topRightText={topRightText}>
      <a href={to}>{children}</a>
    </StyledMenuItem>
  );
});

export default ManuItem;
