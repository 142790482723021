import React, { createContext, useState } from "react";

export const PricingCardsContext = createContext();

const PricingCardsContextProvider = ({ children }) => {
  const carTypes = {
    small: "small",
    medium: "medium",
    large: "large",
  };
  const servicePrices = {
    outside: {
      small: 30,
      medium: 35,
      large: 50,
    },
    inside: {
      small: 35,
      medium: 40,
      large: 55,
    },
    inisdeAndOutside: {
      small: 70,
      medium: 75,
      large: 90,
    },
    upholsteryCleaning: {
      small: 150,
      medium: 150,
      large: 150,
    },
    premiumInside: 200,
    washingAndWaxing: {
      threeMonths: 70,
      sixMonths: 110,
    },
    headlightRegeneration: 100,
  };
  const [selectedCarType, setSelectedCarType] = useState(carTypes.small);

  const setSmall = () => setSelectedCarType(carTypes.small);
  const setMedium = () => setSelectedCarType(carTypes.medium);
  const setLarge = () => setSelectedCarType(carTypes.large);
  const value = {
    selectedCarType,
    setSmall,
    setMedium,
    setLarge,
    carTypes,
    servicePrices,
  };
  return (
    <PricingCardsContext.Provider value={value}>
      {children}
    </PricingCardsContext.Provider>
  );
};
export default PricingCardsContextProvider;
