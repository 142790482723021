import React from "react";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Button, Icon, message } from "antd";
import "antd/lib/button/style/index.css";
import "antd/lib/icon/style/index.css";
import "antd/lib/message/style/index.css";
import "antd/lib/form/style/index.css";
import { InputField, TextAreaField, FormItem } from "./FormItems";
import axios from "axios";

message.config({
  top: 100,
});

const StyledButton = styled(Button)`
  && {
    color: #40a9ff;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  form {
    width: 90%;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Title = styled.div`
  font-size: 2rem;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.whitest};
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    font-size: 2.5rem;
  }
`;
const Hightlight = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.yellow};
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Podaj poprawny adres email")
    .max(254, "Email za długi")
    .required("Musisz podać swój email")
    .trim(),
  content: yup
    .string()
    .max(1000, "Wiadomość nie może przekraczać 1000 znaków")
    .required("Co możemy dla Ciebie zrobić?")
    .trim(),
});
const initialValues = {
  email: "",
  content: "",
};
const contentPlaceholder =
  "Przykładowa wiadomość:\n\n" +
  "Cześć,\n" +
  "chciałbym umówić się na mycie + woskowanie oraz pranie tapicerki. Macie czas we wtorek?\n" +
  "Można mnie też złapać pod numerem: XXX-XXX-XXX";
const ContactForm = ({ setShowMessengerBubble }) => {
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    return new Promise(async resolve => {
      setSubmitting(true);
      delete values.nazwaFirmy;
      const loadingMessage = message.loading("Wysyłanie...", 0);
      try {
        await axios.post(process.env.CONTACT_URL, values);
        message.success({ content: "Wiadomość wysłana", duration: 4 });
        resetForm();
      } catch (err) {
        message.error({
          content: "Wystąpił błąd, prosimy spróbować później",
          duration: 4,
        });
      }
      loadingMessage();
      resolve();
    });
  };

  const hideMessengerBubble = () => setShowMessengerBubble(false);
  const showMessengerBubble = () => setShowMessengerBubble(true);

  return (
    <Container id="formularzKontaktowy">
      <Title>
        Korzystając z tego formularza otrzymasz <Hightlight>10%</Hightlight>{" "}
        zniżki
      </Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              name="email"
              placeholder="Twój e-mail"
              component={InputField}
              bigLabel="Email"
              type="email"
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              onFocus={hideMessengerBubble}
              onBlur={showMessengerBubble}
            />
            <Field
              name="content"
              placeholder={contentPlaceholder}
              component={TextAreaField}
              label="Treść wiadomości"
              type="textarea"
              onFocus={hideMessengerBubble}
              onBlur={showMessengerBubble}
            />
            <FormItem>
              <StyledButton
                disabled={isSubmitting}
                htmlType="submit"
                size="large"
                shape="round"
              >
                Wyślij
              </StyledButton>
            </FormItem>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ContactForm;
