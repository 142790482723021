import React, { useState, useEffect, useContext } from "react";
import { PricingCardsContext } from "../../../Contexts/PircingCardsContext";
import { FaCarSide, FaTruckPickup, FaCar } from "react-icons/fa";
import ListItem from "./utils/CheckListItem";
import {
  TailWrapper,
  TailTitle,
  CarSelectSection,
  CarTypeIcon,
  CarIconText,
  TailContent,
} from "./utils/styles";
import PriceButton from "./utils/PriceButton";
const Outside = () => {
  const {
    selectedCarType,
    setSmall,
    setMedium,
    setLarge,
    carTypes,
    servicePrices: { outside: servicePrice },
  } = useContext(PricingCardsContext);

  const [price, setPrice] = useState(0);
  useEffect(() => {
    switch (selectedCarType) {
      case carTypes.small:
        setPrice(servicePrice.small);
        break;
      case carTypes.medium:
        setPrice(servicePrice.medium);
        break;
      case carTypes.large:
        setPrice(servicePrice.large);
        break;
      default:
        setPrice("??");
    }
    // eslint-disable-next-line
  }, [selectedCarType]);
  return (
    <TailWrapper id="mycieZewnetrzne">
      <TailTitle>Mycie Zewnętrzne</TailTitle>
      <CarSelectSection>
        <CarTypeIcon
          active={selectedCarType === carTypes.small}
          onClick={setSmall}
        >
          <FaCarSide />
          <CarIconText>MAŁE OSOBOWE Hatchback</CarIconText>
        </CarTypeIcon>
        <CarTypeIcon
          active={selectedCarType === carTypes.medium}
          onClick={setMedium}
        >
          <FaCar />
          <CarIconText>DUŻE OSOBOWE Kombi/Sedan</CarIconText>
        </CarTypeIcon>
        <CarTypeIcon
          active={selectedCarType === carTypes.large}
          onClick={setLarge}
        >
          <FaTruckPickup />
          <CarIconText>SUV/VAN Terenowe</CarIconText>
        </CarTypeIcon>
      </CarSelectSection>
      <TailContent>
        <ListItem>szyby</ListItem>
        <ListItem>karoseria</ListItem>
        <ListItem>progi</ListItem>
        <ListItem>felgi</ListItem>
        <ListItem>osuszanie</ListItem>
        <ListItem>
          <span>impregnacja opon</span>
        </ListItem>
        <ListItem>
          <span>Quick Detailer</span>
        </ListItem>
      </TailContent>
      <PriceButton price={price} />
    </TailWrapper>
  );
};

export default Outside;
