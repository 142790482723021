import React, { useContext } from "react";
import styled from "styled-components";
import Slider from "../Slider/Slider";
import { SliderContext } from "../../Contexts/SliderContext";
import Tail from "./Tail";
import { useStaticQuery, graphql } from "gatsby";

const StyledWrapper = styled.section`
  height: 95vh;
  width: 100%;
`;

const Offer = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      MM: file(relativePath: { eq: "offer/mobilne_mycie.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      MZ: file(relativePath: { eq: "offer/mycie_zewnetrzne.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      MW: file(relativePath: { eq: "offer/mycie_wewnetrzne.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      PL: file(relativePath: { eq: "offer/polerowanie_lamp.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ZL: file(relativePath: { eq: "offer/zabezpieczenie_lakieru.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      PT: file(relativePath: { eq: "offer/pranie_tapicerki.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { index } = useContext(SliderContext);
  return (
    <StyledWrapper id={id}>
      <Slider autoplay={true}>
        <Tail
          active={index === 0}
          bgSrc={data.MM.childImageSharp.fluid}
          text={"Mobilne mycie samochodu"}
          buttonText={"Czytaj więcej"}
          id="mobilneMycie"
        />
        <Tail
          active={index === 1}
          bgSrc={data.MZ.childImageSharp.fluid}
          text={"Mycie zewnętrzne"}
          id="mycieZewnetrzne"
        />
        <Tail
          active={index === 2}
          bgSrc={data.MW.childImageSharp.fluid}
          text={"Mycie wewnętrzne"}
          id="mycieWewnetrzne"
        />
        <Tail
          active={index === 3}
          bgSrc={data.PL.childImageSharp.fluid}
          text={"Polerowanie lamp"}
          id="polerowanieLamp"
        />
        <Tail
          active={index === 4}
          bgSrc={data.ZL.childImageSharp.fluid}
          text={"Zabezpieczenie lakieru"}
          id="zabezpieczenieLakieru"
        />
        <Tail
          active={index === 5}
          bgSrc={data.PT.childImageSharp.fluid}
          text={"Pranie tapicerki"}
          id="pranieTapicerki"
        />
      </Slider>
    </StyledWrapper>
  );
};

export default Offer;
