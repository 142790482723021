import React, { useContext } from "react";
import Dot from "./Dot";
import { SliderContext } from "../../Contexts/SliderContext";

const Dots = ({ tails }) => {
  const { index: currentSliderIndex } = useContext(SliderContext);
  return (
    <>
      {tails.map((_, id) => (
        <Dot
          key={`slider_dot_${id}`}
          id={id}
          isActive={currentSliderIndex === id}
        />
      ))}
    </>
  );
};

export default Dots;
