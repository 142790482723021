import styled from "styled-components";
import { FaCheck, FaPlus } from "react-icons/fa";
import Img from "gatsby-image";

export const TailWrapper = styled.div`
  width: 450px;
  height: 650px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  border-radius: 15px;
  border-bottom-right-radius: 150px;
  border-bottom-left-radius: 150px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  position: relative;
  overflow: hidden;
`;
export const PopularLabel = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.whitest};
  top: 20px;
  right: -45px;
  transform: rotate(45deg);
  padding: 10px 40px;
  font-weight: bold;
  font-size: 1.1rem;
`;
export const TailTitle = styled.h2`
  color: ${({ theme }) => theme.colors.whitest};
  margin-top: 50px;
  padding: 0 5px;
  text-align: center;
`;
export const CarSelectSection = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
`;
export const CarTypeIcon = styled.div.attrs({
  role: "button",
})`
  padding: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transform: scale(${({ active }) => (active ? 1.3 : 1)});
  background-color: ${({ theme, active }) =>
    active ? theme.colors.blue : null};
  transition: transform 0.5s ease, background-color 0.5s ease;
  border-radius: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: 24%;
`;
export const CarIconText = styled.h3`
  font-size: 0.6rem;
  width: 100%;
  margin: 0;
`;
export const TailContent = styled.div`
  /*TODO:
  center list items between car type choise and price
*/
  width: 90%;
`;
export const StyledListItem = styled.div`
  line-height: 40px;
  margin-left: 2rem;
  text-indent: -2rem;
`;
export const CheckMark = styled(FaCheck)`
  transform: translateY(4px);
  margin-right: 8px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.yellow};
`;
export const ListText = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  & span {
    font-weight: bold;
  }
`;
export const PlusMark = styled(FaPlus)`
  transform: translateY(2px);
  margin-right: 5px;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.yellow};
`;
export const CardImg = styled(Img)`
  width: 90%;
  border-radius: 5px;
  max-height: 200px;
  &:hover {
    transform: scale(1.1);
  }
`;
