import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "../utils/theme";
import Header from "../components/Header/Header";
import SEO from "../components/Seo";
import Offer from "../components/Offer/Offer";
import PriceList from "../components/PriceList/PriceList";
import MobileWashing from "../components/MobileWashing/MobileWashing";
import SliderContextProvider from "../Contexts/SliderContext";
import MessengerBubble from "../components/MessengerBubble";
import CookieReminder from "../components/CookieReminder";
import { headerHeight } from "../utils/constants";
import vapor_bg from "../images/vapor_bg.png";
import Contact from "../components/Contact/Contact";
import "../utils/fonts/fonts.css";
import Footer from "../components/Footer";
const GlobalStyles = createGlobalStyle`
html{
  scroll-behavior: smooth;
  font-family: "Quicksand";
}
body,html{
  margin: 0;
  padding: 0;
  @media (min-width:${({ theme }) => theme.MQ.mobile}){
    scroll-padding-top: ${headerHeight - 1}px;
  }
  scroll-padding-top: 1px;
}
body{
  width:100%;
}
*,*::before,*::after{
  box-sizing: border-box;
}
.anticon-spin{
  @keyframes spin {
    from{
      transform:rotate(0);
    }
    to{
      transform:rotate(360deg);
    }
  }
  animation: spin 2s linear infinite;
}
`;
const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background: url(${vapor_bg}) center center;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

const IndexPage = () => {
  const [cookieReminderShown, setCookieReminderShown] = useState(true);
  const [showMessengerBubble, setShowMessengerBubble] = useState(true);
  useEffect(() => {
    try {
      const parsedValue = JSON.parse(
        window.localStorage.getItem("cookieReminderShown")
      );
      setCookieReminderShown(parsedValue ? true : false);
    } catch (err) {
      setCookieReminderShown(false);
    }
  }, []);
  const handleCloseReminder = () => {
    setCookieReminderShown(true);
    window.localStorage.setItem("cookieReminderShown", "true");
  };
  return (
    <ThemeProvider theme={theme}>
      <>
        <StyledContainer>
          <SEO title="GarCar Detailing-Mobilna myjnia parowa" />
          <GlobalStyles />
          <Header />
          <SliderContextProvider>
            <Offer id="oferta" />
          </SliderContextProvider>
          <MobileWashing id="mobilneMycie" />
          <PriceList id="cennik" />
          <Contact
            id="kontakt"
            setShowMessengerBubble={setShowMessengerBubble}
          />
          <Footer />
          {showMessengerBubble && (
            <MessengerBubble href="https://m.me/garcarmyjnia" />
          )}
          {!cookieReminderShown && (
            <CookieReminder onClose={handleCloseReminder} />
          )}
        </StyledContainer>
      </>
    </ThemeProvider>
  );
};

export default IndexPage;
