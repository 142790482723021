import React, { useContext } from "react";
import styled from "styled-components";
import { SliderContext } from "../../Contexts/SliderContext";
import { CarouselContext } from "./Slider";

const StyledDot = styled.div`
  width: 14px;
  height: 14px;
  border: 2px solid ${({ theme }) => theme.colors.whitest};
  border-radius: 50%;
  background-color: ${({ isActive, theme }) => isActive && theme.colors.white};
  transition: background-color 0.4s ease;
  &:hover {
    cursor: pointer;
  }
`;

const Dot = React.memo(({ id, isActive }) => {
  const { setIndex } = useContext(SliderContext);
  const { resetInterval } = useContext(CarouselContext);
  const handleClick = () => {
    setIndex(id);
    resetInterval();
  };
  return <StyledDot onClick={handleClick} isActive={isActive} />;
});

export default Dot;
