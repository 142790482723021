import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { PricingCardsContext } from "../../../Contexts/PircingCardsContext";
import ListItem from "./utils/CheckListItem";
import {
  TailWrapper,
  TailTitle,
  CarSelectSection as DurablilitySection,
  TailContent,
} from "./utils/styles";
import PriceButton from "./utils/PriceButton";

const SubTitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;
`;
const DurablilityBox = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  border-radius: 10px;
  border: ${({ theme }) => `2px solid ${theme.colors.blue}`};
  transform: scale(${({ active }) => (active ? 1.2 : 1)});
  background-color: ${({ active, theme }) =>
    active ? theme.colors.blue : null};
  transition: transform 0.5s ease, background-color 0.5s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const WashingAndWaxing = () => {
  const {
    servicePrices: { washingAndWaxing: servicePrice },
  } = useContext(PricingCardsContext);
  const durablilities = {
    threeMonths: "3mies",
    sixMonths: "6mies",
  };
  const [selectedDurability, setSelectedDurability] = useState(
    durablilities.threeMonths
  );

  useEffect(() => {
    if (selectedDurability === durablilities.threeMonths) {
      setPrice(servicePrice.threeMonths);
    } else {
      setPrice(servicePrice.sixMonths);
    }
    // eslint-disable-next-line
  }, [selectedDurability]);
  const [price, setPrice] = useState(0);

  const selectThreeMonths = () =>
    setSelectedDurability(durablilities.threeMonths);
  const selectSixMonths = () => setSelectedDurability(durablilities.sixMonths);
  return (
    <TailWrapper id="zabezpieczenieLakieru">
      <TailTitle>Mycie + Zabezpieczenie twardym woskiem</TailTitle>
      <SubTitle>Trwałość:</SubTitle>
      <DurablilitySection>
        <DurablilityBox
          onClick={selectThreeMonths}
          active={selectedDurability === durablilities.threeMonths}
        >
          3 miesiące
        </DurablilityBox>
        <DurablilityBox
          onClick={selectSixMonths}
          active={selectedDurability === durablilities.sixMonths}
        >
          6 miesięcy
        </DurablilityBox>
      </DurablilitySection>
      <TailContent>
        <ListItem>Mycie parą: karoserii, szyb, felg, progów</ListItem>
        <ListItem>Nabłyszczenie opon</ListItem>
        <ListItem>Odtłuszczenie lakieru</ListItem>
        <ListItem>
          Aplikacja <span>twardego</span> wosku na karoserię
        </ListItem>
      </TailContent>
      <PriceButton from price={price} />
    </TailWrapper>
  );
};

export default WashingAndWaxing;
