import React from "react";
import styled from "styled-components";
import { Form as AntForm, Input } from "antd";
import "antd/lib/button/style/index.css";
import "antd/lib/input/style/index.css";

export const FormItem = AntForm.Item;
const { TextArea } = Input;

const StyledFormItem = styled(FormItem)`
  width: 100%;
  &&& label {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.2rem;
  }
  &&& .ant-form-explain {
    font-size: 1.1rem;
  }
`;
const StyledInput = styled(Input)`
  max-width: 300px;
`;
const StyledTextArea = styled(TextArea)`
  && {
    font-size: 1.3rem;
    font-weight: bold;
    max-width: 550px;
  }
`;
export const InputField = ({
  field,
  form: { touched, errors },
  inputLabel,
  bigLabel,
  type = "input",
  prefix,
  ...props
}) => {
  const errorMessage = touched[field.name] && errors[field.name];
  return (
    <StyledFormItem
      help={errorMessage}
      validateStatus={errorMessage && "warning"}
      label={bigLabel}
    >
      <StyledInput
        {...field}
        {...props}
        prefix={prefix}
        type={type}
        addonBefore={inputLabel}
      />
    </StyledFormItem>
  );
};
export const TextAreaField = ({
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  const errorMessage = touched[field.name] && errors[field.name];
  return (
    <StyledFormItem
      help={errorMessage}
      validateStatus={errorMessage ? "warning" : ""}
      label={label}
    >
      <StyledTextArea
        {...field}
        {...props}
        autoSize={{
          minRows: 8,
          maxRows: 8,
        }}
      />
    </StyledFormItem>
  );
};
