import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import {
  FaFacebookSquare,
  FaInstagram,
  FaPhone,
  FaRegClock,
} from "react-icons/fa";
import ContactForm from "./ContactForm/ContactForm";

const Background = styled(BackgroundImage)`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    filter: brightness(50%);
    @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
      background-attachment: fixed;
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;

  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    flex-direction: row;
  }
`;
const ContactInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const Title = styled.h1`
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 4rem;
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    display: none;
  }
`;
const PhoneContainer = styled.a`
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    font-size: 3rem;
  }
`;
const PhoneIcon = styled(FaPhone)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  transform: scaleX(-1) translateY(2px);
`;
const OpeningHoursContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 5px;
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    font-size: 2rem;
  }
`;
const Clock = styled(FaRegClock)`
  transform: translateY(6px);
  margin-right: 5px;
`;
const SocialsContainer = styled.div`
  display: flex;
  font-size: 3.5rem;
  margin-top: 10px;
`;
const SocialLink = styled.a.attrs({
  target: "_blank",
})`
  color: ${({ theme }) => theme.colors.whitest};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
const About = styled.div`
  width: 95%;
  max-width: 768px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.whitest};
  margin: 15px 0;
`;
const WorkingHoursNotice = styled.span`
  max-width: 95%;
  color: ${({ theme }) => theme.colors.whitest};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
`;

const Contact = ({ id, setShowMessengerBubble }) => {
  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "kontakt/desktop.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "kontakt/mobile.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const sources = [
    imageData.mobile.childImageSharp.fluid,
    {
      ...imageData.desktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Background id={id} fluid={sources} alt="Zlot nocnej jazdy - Arena Glwice">
      <ContentWrapper>
        <ContactInfo>
          <Title>Kontakt</Title>
          <PhoneContainer href="tel:667-469-929">
            <PhoneIcon />
            Telefon: 667-469-929
          </PhoneContainer>
          <OpeningHoursContainer>
            <Clock />
            <span>pon. - pt. 9:00-17:00</span>
          </OpeningHoursContainer>
          <OpeningHoursContainer>
            <Clock />
            <span>sobota 09:00-14:00</span>
          </OpeningHoursContainer>
          <WorkingHoursNotice>
            Przy wyjazdach do klientów nie trzymamy się sztywno godzin pracy :)
          </WorkingHoursNotice>
          <About>
            Chętnie podejmujemy współpracę z firmami posiadającymi flotę
            samochodów służbowych jak i również komisami itp. Jesteśmy otwarci
            na potrzeby klientów i staramy się traktować każdego z nich
            indywidualnie, co sprawia że nasza firma cieszy się stale rosnącym
            zainteresowaniem.
            {/* Osoby zainteresowane zapraszamy do kontaktu.*/}
          </About>
          <SocialsContainer>
            <SocialLink
              href="https://www.facebook.com/garcarmyjnia/"
              alt="Nasz Facebook"
            >
              <FaFacebookSquare />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/garcar_myjniaparowa/"
              alt="Nasz Instagram"
            >
              <FaInstagram />
            </SocialLink>
          </SocialsContainer>
        </ContactInfo>
        <ContactForm setShowMessengerBubble={setShowMessengerBubble} />
      </ContentWrapper>
    </Background>
  );
};

export default Contact;
