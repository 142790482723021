import React, { useState, useEffect, useContext } from "react";
import { PricingCardsContext } from "../../../Contexts/PircingCardsContext";
import ListItem from "./utils/CheckListItem";
import { TailWrapper, TailTitle, TailContent } from "./utils/styles";
import PriceButton from "./utils/PriceButton";

import CardImg from "./utils/CardImg";

import { useStaticQuery, graphql } from "gatsby";

const UpholsteryCleaning = () => {
  const imageData = useStaticQuery(graphql`
    query {
      UpCleaning: file(relativePath: { eq: "offer/pranie_tapicerki.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const {
    selectedCarType,
    carTypes,
    servicePrices: { upholsteryCleaning: servicePrice },
  } = useContext(PricingCardsContext);

  const [price, setPrice] = useState(0);
  useEffect(() => {
    switch (selectedCarType) {
      case carTypes.small:
        setPrice(servicePrice.small);
        break;
      case carTypes.medium:
        setPrice(servicePrice.medium);
        break;
      case carTypes.large:
        setPrice(servicePrice.large);
        break;
      default:
        setPrice("??");
    }
    // eslint-disable-next-line
  }, [selectedCarType]);
  return (
    <TailWrapper id="pranieTapicerki">
      <TailTitle>Pranie Tapicerki</TailTitle>
      <CardImg
        fluid={imageData.UpCleaning.childImageSharp.fluid}
        alt="Pranie Tapicerki"
      />
      <TailContent>
        <ListItem>odkurzanie samochodu</ListItem>
        <ListItem>
          <span>pranie ekstrakcyjne</span>
        </ListItem>
        <ListItem>usuwanie plam i zapachów</ListItem>
      </TailContent>
      <PriceButton from price={price} />
    </TailWrapper>
  );
};

export default UpholsteryCleaning;
