import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  display: block;
  text-decoration: none;
  margin-top: auto;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
`;
const StyledButton = styled.button.attrs({
  title: "Zarezerwuj termin",
})`
  position: relative;
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 2.75rem;
  min-width: 200px;
  padding: 3px 10px;
  border-radius: 15px;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  outline: 0;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  transition: border-radius 0.5s ease;
  &:hover {
    border-radius: 50px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 200px;
    display: block;
    background-color: rgba(255, 255, 255, 0.75);
    transform: rotate(-55deg) translateX(220px);
    animation: flash 1.3s ease;
    @keyframes flash {
      to {
        transform: rotate(-55deg) translateX(-100px);
      }
    }
  }
`;
const Text = styled.p`
  margin-top: 5px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: underline;
  &::after {
    content: "!";
    color: ${({ theme }) => theme.colors.whitest};
    font-weight: bold;
  }
`;
const PriceButton = ({ price, from }) => {
  return (
    <StyledLink href="#formularzKontaktowy">
      <StyledButton key={price}>
        {from && "Od "}
        {price} zł
      </StyledButton>
      <Text>Umów się online</Text>
    </StyledLink>
  );
};

export default PriceButton;
