import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  useRef,
} from "react";
import styled from "styled-components";
import Hammer from "react-hammerjs";
import { SliderContext } from "../../Contexts/SliderContext";
import Dots from "./Dots";

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
const DotsWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 200px;
  z-index: 10;
`;

export const CarouselContext = createContext();

const Slider = ({ children, autoplay }) => {
  const { setIndex } = useContext(SliderContext);
  const handleSwipe = swipe => {
    if (swipe.direction === 2) slideRight();
    else if (swipe.direction === 4) slideLeft();
  };
  const slideRight = () => {
    if (autoplay) resetInterval();
    setIndex(curr => (curr < children.length - 1 ? curr + 1 : 0));
  };

  const slideLeft = () => {
    if (autoplay) resetInterval();
    setIndex(curr => (curr > 0 ? curr - 1 : children.length - 1));
  };
  const [isAutoplaying, setIsAutoplaying] = useState(false);
  const interval = useRef(null);
  const resetInterval = () => {
    stopInterval();
    interval.current = setInterval(slideRight, 5000);
    setIsAutoplaying(true);
  };
  const stopInterval = () => {
    clearInterval(interval.current);
    setIsAutoplaying(false);
  };
  const contextValue = { resetInterval, stopInterval, isAutoplaying };
  useEffect(() => {
    if (autoplay) {
      resetInterval();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Hammer onSwipe={handleSwipe}>
      <StyledWrapper>
        <CarouselContext.Provider value={contextValue}>
          {children}
        </CarouselContext.Provider>
        <DotsWrapper>
          <CarouselContext.Provider value={contextValue}>
            <Dots tails={children} />
          </CarouselContext.Provider>
        </DotsWrapper>
      </StyledWrapper>
    </Hammer>
  );
};
export default Slider;
