import React from "react";
import styled from "styled-components";
import PricingCardsContextProvider from "../../Contexts/PircingCardsContext";
import Outside from "./PricingCards/Outside";
import Inside from "./PricingCards/Inside";
import Premium from "./PricingCards/Premium";
import UpholsteryCleaning from "./PricingCards/UpholsteryCleaning";
import PremiumInside from "./PricingCards/PremiumInside";
import WashingAndWaxing from "./PricingCards/WashingAndWaxing";
import HeadlightRegeneration from "./PricingCards/HeadlightRegeneration";

const StyledWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Cennik = ({ id }) => {
  return (
    <StyledWrapper id={id}>
      <PricingCardsContextProvider>
        <Outside />
        <Inside />
        <Premium />
        <HeadlightRegeneration />
        <WashingAndWaxing />
        <UpholsteryCleaning />
        <PremiumInside />
      </PricingCardsContextProvider>
    </StyledWrapper>
  );
};
export default Cennik;
